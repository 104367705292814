import {Toast} from "vant";

let from;

export default {
    openUrl(url) {
        this.initFrom();
        if (from.includes('android')) {
            window.androidJs.openUrl(url)
        }
        else if (from.includes('iOS')) {
            window.webkit.messageHandlers.openUrl.postMessage({url: url})
        }
        else {
            window.open(url, '_blank');
        }
    },
    playGame(url, hookUrl){
        this.initFrom();
        if (from === 'android_box') {
            window.androidJs.playGame(url, hookUrl)
        }
        else {
            window.open(url, '_blank');
        }
    },
    exitGame(){
        this.initFrom();
        if (from === 'android_box') {
            window.androidJs.exitGame()
        }
        else if(from === 'h5'){
            window.close();
        }
    },
    openUrlBrowser(url) {
        this.initFrom();
        if (from.includes('android')) {
            window.androidJs.openUrl(url)
        }
        else if (from.includes('iOS')) {
            window.webkit.messageHandlers.openUrl.postMessage({url: url})
        }
        else {
            window.open(url, '_blank');
        }
    },
    openFullDialog(url) {
        this.initFrom();
        if (from === 'android_sdk') {
            window.androidJs.openFullDialog(url)
        }
        else  {
            window.webkit.messageHandlers.openFullDialog.postMessage({url: url})
        }

    },
    closeFullDialog() {
        this.initFrom();
        if (from === 'android_sdk') {
            window.androidJs.closeFullDialog()
        }
        else  {
            window.webkit.messageHandlers.closeFullDialog.postMessage(null)
        }
    },
    call(tel) {
        this.initFrom();
        if (from === 'android_sdk') {
            window.androidJs.call(tel)
        }
        else  {
            window.webkit.messageHandlers.call.postMessage({tel: tel})
        }

    },
    startIntent(url){
        this.initFrom();
        if (from === 'android_sdk' || from === 'android_box') {
            window.androidJs.startIntent(url)
        }
        else if (from.includes('iOS')) {
            window.webkit.messageHandlers.startIntent.postMessage({url: url})
        }
        else {
            window.open(url, '_blank');
        }
    },
    close(){
        this.initFrom();
        if (from === 'android_sdk') {
            window.androidJs.close()
        }
        else  {
            window.webkit.messageHandlers.close.postMessage(null)
        }
    },
    logout(){
        this.initFrom();
        if (from === 'android_sdk') {
            window.androidJs.logout()
        }
        else  {
            window.webkit.messageHandlers.logout.postMessage(null)
        }
    },
    copy(content){
        this.initFrom();
        if (from.includes('android')) {
            try {
                window.androidJs.copy(content)
            } catch (e) {}
            try {
                window.androidJs.copystring(content)
            } catch (e) {}
        }
        else if (from.includes('iOS')) {
            window.webkit.messageHandlers.copy.postMessage({content: content})
        }
        else {
            const input = document.createElement('input')
            document.body.appendChild(input)
            input.setAttribute('value', content)
            input.select()
            if (document.execCommand('copy')) {
                document.execCommand('copy')
            }
            document.body.removeChild(input);
        }
    },
    setJs(jsz){
        this.initFrom();
        if (from === 'android_sdk') {
            window.androidJs.setJs(jsz)
        }
        else  {
            //window.webkit.messageHandlers.setJs.postMessage({jsz: jsz})
        }
    },
    initFrom() {
        if (!from) {
            from = localStorage.getItem('from') || 'h5';
        }
    }


}